import { Component, OnInit, Input, HostListener, OnDestroy, ViewChild } from '@angular/core';
import { UserApplicationAccessInfoStatus, OrganisationsService, GetOrgRequestParams, Organisation } from '@agilicus/angular';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { map, catchError, startWith, switchMap, concatMap, takeUntil } from 'rxjs/operators';
import { capitalizeFirstLetter, getEmptyResourceAccessState, getEmptyResourceComponentState, getRouterLinkFromPath } from '../utils';
import { TabHeaderPosition } from '../tab-header-position.enum';
import { ResourceAccessState } from '../resource-access-state';
import { OrgIconStructure } from '../org-icon-structure';
import { OrgResourcesStructure } from '../org-resources-structure';
import { OrgBasicInfo } from '../org-basic-info';
import { ResourceType } from '../resource-type.enum';
import { ResourceAccess } from '../resource-access';
import { ResourceAccessLevel } from '../resource-access-level.enum';
import { ResourceComponentState } from '../resource-component-state';
import { AppInitService } from '../app.init';
import { ResourceAccessInfo } from '@app/resource-access-info-type';
import { MakeResourceAccessFactory, ResourceAccessFactory } from './resource-access-factory';
import { LabelInfo, LabelsByAccess } from '@app/app-launcher/app-launcher.component';
import { AppIconFolderStructureComponent } from '@app/app-icon-folder-structure/app-icon-folder-structure.component';

@Component({
  selector: 'app-resource-launcher',
  templateUrl: './resource-launcher.component.html',
  styleUrls: ['./resource-launcher.component.scss'],
})
export class ResourceLauncherComponent implements OnInit, OnDestroy {
  @ViewChild('appIconFolderStructureRef', { static: false }) appIconFolderStructureRef: AppIconFolderStructureComponent | undefined;
  @Input() public resourceComponentState$: Observable<ResourceComponentState> = of(getEmptyResourceComponentState());
  @Input() public resourceType: ResourceType;
  @Input() public labelsInfo$: Observable<LabelsByAccess> = of(getEmptyLabelsByAccess());
  public allLabels: LabelInfo[] = [];
  public grantedLabels: LabelInfo[] = [];
  public requestedLabels: LabelInfo[] = [];
  private unsubscribe$: Subject<void> = new Subject<void>();
  private resourceAccessFactory: ResourceAccessFactory;
  public componentContext$: Observable<{
    state: ResourceAccessState;
  }>;
  public state: ResourceAccessState;
  public userId = '';
  public userName = '';
  public userRootOrgId = '';
  public orgIdParam = '';
  public resourceAccessState$: Subject<ResourceAccessState> = new Subject();
  public resourceAccessData: Array<ResourceAccess> = [];
  public isSmallScreen = false;
  private smallScreenSizeBreakpoint = 900;
  private orgIdToOrgNameMap: Map<string, string> = new Map();
  public orgIdToParentOrgIdMap: Map<string, string> = new Map();
  public title = '';
  public selectedType: string = '';
  public types = [ResourceType.application, ResourceType.launcher, ResourceType.desktop, ResourceType.share, ResourceType.ssh];
  public searchTerm: string = '';
  filteredResources: Array<ResourceAccess> = [];
  public filterActive = false;
  public isInitialized: boolean = false;
  public parentOrgName$: Observable<string>;
  public showMineTabStatusIcons = false;

  // This is required in order to reference the enums in the html template.
  public accessLevelEnum = UserApplicationAccessInfoStatus.AccessLevelEnum;

  public capitalizeFirstLetter = capitalizeFirstLetter;

  // For swiping tabs:
  private tabsLength = 3;
  public selectedTab = 0;
  private SWIPE_ACTION = { LEFT: 'swipeleft', RIGHT: 'swiperight' };

  public selectedLabels: LabelInfo[] = [];
  public selectedTypes: { [key: string]: boolean } = {};

  public searchFilterTextValue = '';

  constructor(private router: Router, private appInitService: AppInitService, private orgsService: OrganisationsService) {
    this.componentContext$ = this.resourceAccessState$.asObservable().pipe(
      startWith(0),
      switchMap(() => this.getResourceState$().pipe(map((state) => ({ state }))))
    );
  }

  private doResize(): void {
    if (window.innerWidth < this.smallScreenSizeBreakpoint) {
      this.isSmallScreen = true;
    } else {
      this.isSmallScreen = false;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.doResize();
  }

  @HostListener('swipeleft', ['$event'])
  onSwipeLeft(event: Event): void {
    this.onSwipe('swipeleft');
  }

  @HostListener('swiperight', ['$event'])
  onSwipeRight(event: Event): void {
    this.onSwipe('swiperight');
  }

  public ngOnInit(): void {
    this.resourceAccessFactory = MakeResourceAccessFactory();
    this.isSmallScreen = window.innerWidth < this.smallScreenSizeBreakpoint;
    this.doResize();
    this.componentContext$ = this.resourceAccessState$.asObservable().pipe(
      startWith(0),
      switchMap(() => this.getResourceState$().pipe(map((state) => ({ state }))))
    );
    combineLatest([this.componentContext$, this.labelsInfo$])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([componentContextResp, LabelsByAccess]) => {
        this.state = componentContextResp.state;
        const orgIdsWithResourcesList = this.getOrgIdsWithResources(this.state.grantedResources);
        if (orgIdsWithResourcesList.length === 1 && this.orgIdParam !== orgIdsWithResourcesList[0]) {
          // If only one org has resources and we are not currently in that org, then navigate to that org:
          this.router.navigate([getRouterLinkFromPath()], {
            queryParams: { org_id: orgIdsWithResourcesList[0] },
          });
        }
        this.allLabels = LabelsByAccess.all;
        this.requestedLabels = LabelsByAccess.requested;
        this.grantedLabels = LabelsByAccess.granted;
        this.selectedLabels = [];
        this.isInitialized = true;
      });
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public onLabelCheckboxChange(label: LabelInfo, state: ResourceAccessState): void {
    label.selected = !label.selected;
    this.selectedLabels = this.getLabelsForFilter(state).filter((selectedLabel) => selectedLabel.selected);
  }

  public getResourcesNotInLabels(resourceList: ResourceAccess[]): Array<ResourceAccess> {
    const labelObjectIds = this.allLabels.reduce((acc, label) => {
      return acc.concat(label.objects.map((obj) => obj.object_id));
    }, []);

    let list = resourceList.filter((resource) => !labelObjectIds.includes(resource.id));
    return list;
  }

  private getResourceState$(): Observable<ResourceAccessState> {
    return this.resourceComponentState$.pipe(
      map((resourceAccessResponse: ResourceComponentState) => {
        this.userRootOrgId = resourceAccessResponse.userRootOrgId;
        this.userId = resourceAccessResponse.userId;
        this.orgIdParam = resourceAccessResponse.orgIdParam;
        this.title = resourceAccessResponse.title;
        this.setRequestedOrgsMap(resourceAccessResponse.resourceAccessInfo);
        this.resourceAccessData = this.getResourceAccessData(resourceAccessResponse.resourceAccessInfo);
        return this.createResourceAccessState(this.resourceAccessData);
      }),
      concatMap((resourceAccessState: ResourceAccessState) => this.updateStateWithRequestFlow$(resourceAccessState)),
      catchError((_) => {
        return of(getEmptyResourceAccessState());
      })
    );
  }

  public getLabelsForFilter(state: ResourceAccessState): LabelInfo[] {
    if (state.requestFlowDisabled) {
      return this.grantedLabels;
    }
    return this.allLabels;
  }

  private getInternalApplications(): ResourceAccess[] {
    const enabledResources: ResourceAccess[] = [];
    if (
      this.appInitService.environment.internalApplications &&
      this.appInitService.environment.internalApplications.alerts &&
      this.appInitService.environment.internalApplications.alerts.enabled
    ) {
      for (const alert of this.appInitService.environment.internalApplications.alerts.alertTypes) {
        const resource: ResourceAccess = {
          id: alert.id,
          user_id: this.userId,
          org_id: this.userRootOrgId,
          org_name: this.orgIdToOrgNameMap.get(this.userRootOrgId),
          name: alert.name,
          access_level: 'granted',
          resource_url: alert.resource_url,
          resource_type: ResourceType.internal,
          icon_url: alert.icon_url,
          description: alert.description,
          is_remote_app: false,
        };
        enabledResources.push(resource);
      }
    }
    return enabledResources;
  }

  private getResourceAccessData(resourceAccessResponse: Array<ResourceAccessInfo>): Array<ResourceAccess> {
    const resourceAccessData: Array<ResourceAccess> = [];
    if (this.resourceType === ResourceType.application) {
      for (const resourceAccessElement of this.getInternalApplications()) {
        resourceAccessData.push(resourceAccessElement);
      }
    }
    for (const resourceAccess of resourceAccessResponse) {
      resourceAccessData.push(this.resourceAccessFactory.makeResourceAccess(resourceAccess));
    }
    return resourceAccessData;
  }

  private setRequestedOrgsMap(resourceAccessResponse: Array<ResourceAccessInfo>): void {
    // Reset the map.
    this.orgIdToOrgNameMap.clear();
    for (const request of resourceAccessResponse) {
      this.orgIdToOrgNameMap.set(request.status.org_id, request.status.org_name);
    }
  }

  private createResourceAccessState(resourceAccessResponse: Array<ResourceAccess>): ResourceAccessState {
    const resourceAccessState = getEmptyResourceAccessState();
    // Initialize the root orgs folder and resource structure. This is important
    // since the root org may not have a resource directly under it.
    this.initializeOrgInAllResourceStateLevels(resourceAccessState, this.userRootOrgId);
    // All resources:
    this.createOrgIconStructureMap(resourceAccessResponse, resourceAccessState);
    // Requested resources:
    this.createOrgIconStructureMap(resourceAccessResponse, resourceAccessState, ResourceAccessLevel.requested);
    // Granted resources:
    this.createOrgIconStructureMap(resourceAccessResponse, resourceAccessState, ResourceAccessLevel.granted);
    this.loadParentOrgName(resourceAccessState);
    return resourceAccessState;
  }

  /**
   * Creates the folders and resources icon mapping for the specified section/tab.
   */
  private createOrgIconStructureMap(
    resourceAccessResponse: Array<ResourceAccess>,
    resourceAccessState: ResourceAccessState,
    accessLevel?: ResourceAccessLevel
  ): void {
    for (const resourceAccess of resourceAccessResponse) {
      this.initializeOrgInResourceStateLevel(resourceAccessState, resourceAccess.org_id, accessLevel);
    }
    for (const resourceAccess of resourceAccessResponse) {
      if (!accessLevel) {
        this.createResourceFolderStructure(resourceAccessState.allResources, resourceAccess);
      } else if (accessLevel === ResourceAccessLevel.requested && resourceAccess.access_level === ResourceAccessLevel.requested) {
        this.createResourceFolderStructure(resourceAccessState.requestedResources, resourceAccess);
      } else if (accessLevel === ResourceAccessLevel.granted && resourceAccess.access_level === ResourceAccessLevel.granted) {
        this.createResourceFolderStructure(resourceAccessState.grantedResources, resourceAccess);
      }
    }
  }

  /**
   * Adds the org to the folder/resource structure map for all sections/tabs.
   */
  private initializeOrgInAllResourceStateLevels(resourceAccessState: ResourceAccessState, orgId: string): void {
    // All resources:
    this.initializeOrgInResourceStateLevel(resourceAccessState, orgId);
    // Requested resources:
    this.initializeOrgInResourceStateLevel(resourceAccessState, orgId, ResourceAccessLevel.requested);
    // Granted resources:
    this.initializeOrgInResourceStateLevel(resourceAccessState, orgId, ResourceAccessLevel.granted);
  }

  private createOrgIconStructureWithResources(resourceList: Array<ResourceAccess>): OrgIconStructure {
    return {
      folders: new Map(),
      resources: resourceList,
    };
  }

  /**
   * Adds the org to the folder/resource structure map.
   */
  private initializeOrgInResourceStateLevel(
    resourceAccessState: ResourceAccessState,
    orgId: string,
    accessLevel?: ResourceAccessLevel
  ): void {
    if (!accessLevel) {
      resourceAccessState.allResources.orgIdToIconMap.set(orgId, this.createOrgIconStructureWithResources([]));
    } else if (accessLevel === ResourceAccessLevel.requested) {
      resourceAccessState.requestedResources.orgIdToIconMap.set(orgId, this.createOrgIconStructureWithResources([]));
    } else if (accessLevel === ResourceAccessLevel.granted) {
      resourceAccessState.grantedResources.orgIdToIconMap.set(orgId, this.createOrgIconStructureWithResources([]));
    }
  }

  private createResourceFolderStructure(orgResourcesStructure: OrgResourcesStructure, resourceAccess: ResourceAccess): void {
    const orgResources = orgResourcesStructure.orgIdToIconMap.get(resourceAccess.org_id);
    if (!!orgResources) {
      orgResources.resources?.push(resourceAccess);
      orgResourcesStructure.orgIdToIconMap.set(resourceAccess.org_id, orgResources);
    } else {
      const newOrgIconStructure = this.createOrgIconStructureWithResources([resourceAccess]);
      orgResourcesStructure.orgIdToIconMap.set(resourceAccess.org_id, newOrgIconStructure);
    }
    let parentOrgId = resourceAccess.parent_org_id;
    if (!parentOrgId) {
      return;
    }
    let parentOrgResources = orgResourcesStructure.orgIdToIconMap.get(parentOrgId);
    if (!parentOrgResources) {
      parentOrgId = this.userRootOrgId;
      parentOrgResources = orgResourcesStructure.orgIdToIconMap.get(parentOrgId);
    }
    if (!parentOrgResources) {
      return;
    }
    this.orgIdToParentOrgIdMap.set(resourceAccess.org_id, parentOrgId);
    // Add the suborg folder to the parent org.
    parentOrgResources.folders.set(resourceAccess.org_id, {
      orgId: resourceAccess.org_id,
      orgName: resourceAccess.org_name,
    });
  }

  public getResourceByAccess(resourceAccessState: Array<ResourceAccess>, access: ResourceAccessLevel): Array<ResourceAccess> {
    return resourceAccessState.filter((resourceAccess) => resourceAccess.access_level === access);
  }

  private getOrgName$(parentOrgId: string): Observable<string | undefined> {
    if (!parentOrgId) {
      return of(undefined);
    }
    const parentOrgName = this.orgIdToOrgNameMap.get(parentOrgId);
    if (!!parentOrgName) {
      return of(parentOrgName);
    }
    return this.orgsService.getOrg({ org_id: parentOrgId }).pipe(
      catchError((err: any) => {
        console.log(`Failed to fetch user organisation: ${err}`);
        return undefined;
      }),
      map((org: Organisation | undefined) => {
        const orgName = org?.organisation;
        if (!!orgName) {
          this.orgIdToOrgNameMap.set(parentOrgId, orgName);
        }
        return orgName;
      })
    );
  }

  public getCurrentParentOrgId(resourceAccessState: ResourceAccessState): string {
    const orgIdsWithResourcesList = this.getOrgIdsWithResources(resourceAccessState.grantedResources);
    if (orgIdsWithResourcesList.length === 1) {
      // If only one org has resources, then make that the "parent"
      return orgIdsWithResourcesList[0];
    }
    return this.orgIdToParentOrgIdMap.get(this.orgIdParam) || this.orgIdParam;
  }

  private loadParentOrgName(resourceAccessState: ResourceAccessState): void {
    const parentOrgId = this.getCurrentParentOrgId(resourceAccessState);
    this.parentOrgName$ = this.getOrgName$(parentOrgId).pipe(map((orgName) => (!!orgName ? capitalizeFirstLetter(orgName) : undefined)));
  }

  public returnToParentOrg(resourceType: ResourceType, resourceAccessState: ResourceAccessState): void {
    const parentOrgId = this.getCurrentParentOrgId(resourceAccessState);
    this.router.navigate([`/${resourceType}s`], {
      queryParams: { org_id: parentOrgId },
    });
  }

  public onSwipe(eType: string): void {
    if (eType === this.SWIPE_ACTION.LEFT && this.selectedTab < this.tabsLength) {
      this.selectedTab++;
    } else if (eType === this.SWIPE_ACTION.RIGHT && this.selectedTab > 0) {
      this.selectedTab--;
    }
  }

  public onTabChange(newTabIndex: number): void {
    this.selectedTab = newTabIndex;
  }

  public getOrgNameFromOrgId(orgId: string): string {
    const orgName = this.orgIdToOrgNameMap.get(orgId);
    return orgName ? orgName : orgId;
  }

  public getOrgFolders(orgResourcesStructure: OrgResourcesStructure): Array<OrgBasicInfo> {
    const orgIconData = orgResourcesStructure.orgIdToIconMap.get(this.orgIdParam);
    if (orgIconData) {
      return Array.from(orgIconData.folders.values());
    }
    return [];
  }

  public getSelectedLabels(defaultLabels: Array<LabelInfo>): Array<LabelInfo> {
    if (this.selectedLabels.length === 0) {
      return defaultLabels;
    }
    return defaultLabels.filter((label) => this.isLabelInList(this.selectedLabels, label));
  }

  private isLabelInList(labelList: Array<LabelInfo>, targetLabel: LabelInfo): boolean {
    return labelList.map((label) => label.name).includes(targetLabel.name);
  }

  public getOrgResources(orgResourcesStructure: OrgResourcesStructure): Array<ResourceAccess> {
    const currentOrgIconData = orgResourcesStructure.orgIdToIconMap.get(this.orgIdParam);
    const resourceList = currentOrgIconData?.resources || [];

    return resourceList;
  }

  public doesUserHaveResourcesInAnyOrg(orgResourcesStructure: OrgResourcesStructure): boolean {
    const orgResourcesStructureAsArray = Array.from(orgResourcesStructure.orgIdToIconMap.values());
    for (const orgResourcesStructure of orgResourcesStructureAsArray) {
      if (orgResourcesStructure.resources.length !== 0) {
        return true;
      }
    }
    return false;
  }

  public getOrgIdsWithResources(orgResourcesStructure: OrgResourcesStructure): Array<string> {
    const allUserOrgIdsArray = Array.from(orgResourcesStructure.orgIdToIconMap.keys());
    let orgIdsWithResourcesList = [];
    for (const orgId of allUserOrgIdsArray) {
      const resources = orgResourcesStructure.orgIdToIconMap.get(orgId).resources;
      if (resources.length !== 0) {
        orgIdsWithResourcesList.push(orgId);
      }
    }
    return orgIdsWithResourcesList;
  }

  public onSelectedTypesChange(): void {
    this.appIconFolderStructureRef.applyAllResourceFilters();
  }

  public onSearchTermChange(): void {
    const lowerCaseSearchTerm = this.searchTerm.toLowerCase();
    this.searchFilterTextValue = lowerCaseSearchTerm;
  }

  public clearSearch(): void {
    this.searchTerm = '';
    this.onSearchTermChange();
  }

  public getTabHeaderPositionFromScreenSize(): TabHeaderPosition {
    if (this.isSmallScreen) {
      return TabHeaderPosition.below;
    }
    return TabHeaderPosition.above;
  }

  public refreshResourceState(): void {
    this.resourceAccessState$.next(null);
  }

  private updateStateWithRequestFlow$(resourceAccessState: ResourceAccessState): Observable<ResourceAccessState> {
    const orgParams: GetOrgRequestParams = {
      org_id: this.orgIdParam,
    };
    if (!orgParams.org_id) {
      return of(resourceAccessState);
    }
    return this.orgsService.getOrg(orgParams).pipe(
      catchError((err: any) => {
        console.log(`failed to fetch user organisation: ${err}`);
        return undefined;
      }),
      map((org: Organisation | undefined) => {
        if (org?.owner_config?.disable_user_requests === false) {
          resourceAccessState.requestFlowDisabled = false;
        }
        return resourceAccessState;
      })
    );
  }

  public getNoAssignedResourcesMessageText(): string {
    return `You do not currently have any assigned resources. Please contact your administrator to ask for access, then try reloading twice to refresh your permissions.`;
  }
}

function getEmptyLabelsByAccess(): LabelsByAccess {
  return {
    granted: [],
    requested: [],
    all: [],
  };
}
