<button
  #contextMenuTrigger="matMenuTrigger"
  mat-icon-button
  [matMenuTriggerFor]="contextMenu"
  id="menu-button"
  style="height: 0; width: 0; padding: 0;"
>
</button>

<mat-menu
  #contextMenu="matMenu"
  xPosition="after"
  yPosition="below"
>
  <div
    class="button-container"
    [matTooltip]="getMountButtonTooltip()"
  >
    <button
      *ngIf="!isAlreadyMounted"
      mat-menu-item
      (click)="mountDrive()"
      [disabled]="isMountDisabled()"
    >
      Mount Local Desktop
    </button>
  </div>

  <button
    mat-menu-item
    (click)="unMountDrive()"
    [disabled]="isMountDisabled()"
    *ngIf="isAlreadyMounted"
    [matTooltip]="getUnmountButtonTooltip()"
  >
    Unmount Local Desktop
  </button>

  <button
    mat-menu-item
    (click)="openShareMountDialog()"
    matTooltip="Click to view instructions for mounting this share"
  >
    Manual Mount
  </button>
</mat-menu>


<button
  #launchMenuTrigger="matMenuTrigger"
  mat-icon-button
  [matMenuTriggerFor]="launcherMenu"
  id="launcher-menu-button"
  style="height: 0; width: 0; padding: 0;"
>
</button>

<mat-menu
  #launcherMenu="matMenu"
  xPosition="after"
  yPosition="below"
>
  <div
    class="button-container"
    [matTooltip]="getResourceLauncherTooltip()"
  >
    <button
      mat-menu-item
      [disabled]="disableLaunchWithLauncher()"
      (click)="launchSelectedResource()"
    >
      Launch directly
    </button>
  </div>
  <button
    mat-menu-item
    (click)="openManualDesktopDownload()"
    matTooltip="Click to view instructions for launching this desktop"
  >
    Launch using file download
  </button>
</mat-menu>

<button
  #vncMenuTrigger="matMenuTrigger"
  mat-icon-button
  [matMenuTriggerFor]="vncMenu"
  id="vnc-menu-button"
  style="height: 0; width: 0; padding: 0;"
>
</button>

<mat-menu
  #vncMenu="matMenu"
  xPosition="after"
  yPosition="below"
>
  <div
    class="button-container"
    [matTooltip]="getResourceLauncherTooltip()"
  >
    <button
      mat-menu-item
      [disabled]="disableLaunchWithLauncher()"
      (click)="launchSelectedResource()"
    >
      Launch VNC directly
    </button>
  </div>
  <button
    mat-menu-item
    (click)="openManualVncDesktop()"
    matTooltip="Click to launch VNC in browser"
  >
    Launch VNC in browser
  </button>
</mat-menu>

<button
  mat-icon-button
  [matMenuTriggerFor]="sshMenu"
  id="ssh-menu-button"
  #sshMenuTrigger="matMenuTrigger"
  style="height: 0; width: 0; padding: 0;"
>
</button>

<mat-menu
  #sshMenu="matMenu"
  xPosition="after"
  yPosition="below"
>
  <div
    class="button-container"
    [matTooltip]="getResourceLauncherTooltip()"
  >
    <button
      mat-menu-item
      (click)="launchSelectedResource()"
      [disabled]="disableLaunchWithLauncher()"
    >
      Launch SSH directly
    </button>
  </div>
  <button
    mat-menu-item
    (click)="openSshManuallyFromRightClick()"
    matTooltip="Click to launch the SSH manually. You will need to enter a username and password."
  >
    Launch SSH in browser
  </button>
</mat-menu>

<!-- TODO: fix indenting here -->
<ng-container *ngFor="let resource of resourceList">
<div *ngIf="isValidResourceType(resource.resource_type)" class="icon-container">
  <div
    *ngIf="resource.access_level === 'granted'"
    class="icon-container-button"
    (click)="onGrantedAccessResourceLeftClick($event, resource)"
    (contextmenu)="onGrantedAccessResourceRightClick($event, resource)"
    [class.context-menu]="isRightClickEnabledResource(resource)"
    [class.resource-disabled]="isResourceDisabled(resource)"
  >
    <div class="icon-img-container">
      <div *ngIf="showStatusIcons" class="mat-icon-status-container">
        <mat-icon 
          class="icon-status granted-icon"
          [matTooltip]="'Access has been granted for this ' + resourceType"
        >
          check_circle
        </mat-icon>
      </div>
      <img
        *ngIf="resource.icon_url" 
        alt="icon"
        src="{{ resource.icon_url }}"
      />
      <mat-icon 
        *ngIf="!resource.icon_url"
        class="default-icon"
        [ngClass]="{'default-icon-small': isSmallScreen}"
      >
        {{ getDefaultResourceIcon(resource) }}
      </mat-icon>
    </div>
    <div class="resource-icon-text-container">
      <p>{{ resource.name }}</p>
    </div>
    <div class="resource-icon-text-container">
      <p [matTooltip]="getDescriptionTooltipText(resource)">{{ getResourceDescriptionDisplayValue(resource) }}</p>
    </div>
  </div>

  <div
    *ngIf="resource.access_level === 'requested'"
    (click)="onRequestedResourceClick(resource)"
  >
    <div class="icon-img-container">
      <div class="mat-icon-status-container">
        <mat-icon
          class="icon-status requested-icon"
          [matTooltip]="'Access has been requested but not yet granted for this ' + resourceType"
        >
          help_outline
        </mat-icon>
      </div>
      <img
        *ngIf="resource.icon_url" 
        alt="icon"
        src="{{ resource.icon_url }}"
      />
      <mat-icon 
        *ngIf="!resource.icon_url"
        class="default-icon"
        [ngClass]="{'default-icon-small': isSmallScreen}"
      >
        {{ getDefaultResourceIcon(resource) }}
      </mat-icon>
    </div>
    <div class="resource-icon-text-container">
      <p>{{ resource.name }}</p>
    </div>
    <div class="resource-icon-text-container">
      <p [matTooltip]="getDescriptionTooltipText(resource)">{{ getResourceDescriptionDisplayValue(resource) }}</p>
    </div>
  </div>

  <div
    *ngIf="resource.access_level === 'none'"
    class="no-access-app-icon"
    (click)="onNoAccessResourceClick(resource)"
    [matTooltip]="getNoAccessToolTip(resource)"
  >
    <div class="icon-img-container">
      <div class="mat-icon-status-container"></div>
      <img
        *ngIf="resource.icon_url" 
        alt="icon"
        src="{{ resource.icon_url }}"
      />
      <mat-icon 
        *ngIf="!resource.icon_url"
        class="default-icon"
        [ngClass]="{'default-icon-small': isSmallScreen}"
      >
        {{ getDefaultResourceIcon(resource) }}
      </mat-icon>
    </div>
    <div class="resource-icon-text-container">
      <p>{{ resource.name }}</p>
    </div>
    <div class="resource-icon-text-container">
      <p [matTooltip]="getDescriptionTooltipText(resource)">{{ getResourceDescriptionDisplayValue(resource) }}</p>
    </div>
  </div>
</div>
</ng-container>

<app-progress-spinner-dialog *ngIf="progressSpinner" (closeSpinnerEvent)="onCloseSpinner()"></app-progress-spinner-dialog>
